/** @jsxImportSource theme-ui */

import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { PageBySlugQuery } from "../../graphql-types"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Box, Heading } from "theme-ui"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { getCanonicalUrl } from "../helpers/url-helpers"

interface PageProps {
  data: PageBySlugQuery
  location: Location
}
const PageTemplate: React.FC<PageProps> = ({ data, location }) => {
  const metadata = useSiteMetadata();
  const post = data.mdx
  const canonicalUrl = getCanonicalUrl(
    metadata.siteUrl,
    post.fields.slug
  );
  const title = post.frontmatter.title
  return (
    <Layout
      location={location}
      pageTitle={title}
      canonicalUrl={canonicalUrl}
      description={post.frontmatter.description || post.excerpt}
    >
      <Box as={"article"} itemScope itemType="http://schema.org/Article">
        <header>
          <Heading
            itemProp="headline"
            sx={{
              variant: "headings.articleHeading",
              fontSize: "xxxl",
              mt: "l",
            }}
          >
            {title}
          </Heading>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr />
      </Box>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($id: String!) {
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
